<script lang="ts" setup>
const props = defineProps<{
  dark?: boolean
}>()
</script>

<template>
  <div class="group w-full">
    <div class="relative m-0 block h-full w-full overflow-hidden">
      <div class="w-full">
        <div
          class="absolute z-10 flex h-full w-full flex-col justify-between p-8 text-white transition-colors duration-300"
        >
          <div class="">
            <UiSkeletonText
              class="font-pragmatica-ext mb-4 text-4xl font-bold"
            ></UiSkeletonText>
            <UiSkeletonText class="text-xl"></UiSkeletonText>
          </div>
        </div>
        <div
          class="z-0 block aspect-[640/427] h-full min-h-[350px] w-full animate-pulse overflow-hidden p-0"
          :class="props.dark ? 'bg-black' : 'bg-neutral-100'"
        ></div>
      </div>
    </div>
  </div>
</template>
